<template>
  <div id="divRoot" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile :showMenu="false"></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader :showMenu="false"></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <transition name="fade-in-up">
          <router-view />
        </transition>

      </div>
    </div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTScrollTop from '@/view/layout/extras/ScrollTop';

export default {
  name: 'PrivacyLayout',
  components: {
    KTHeader,
    KTHeaderMobile,
    KTScrollTop
  },
  computed: {
    ...mapGetters([
      'layoutConfig'
    ])
  },
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.setIsMobile();
    }, 50);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setIsMobile();
    },
    setIsMobile() {
      const divRoot = document.getElementById('divRoot');
      if (divRoot) {
        const clientWidth = divRoot.clientWidth;
        const isMobile = this.$isMobile() === true || clientWidth < 560;
        this.$store.commit('set', ['isMobile', isMobile]);
      }
    }
  }
};
</script>